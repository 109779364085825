import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';

import ImgGiving from '../img/img-givingdate.png';

export default function Template({ data }) {
  return (
    <Layout nav="colored-nav">
      <section id="givingthanks-hero" className="mt130 mb60">
        <div className="container">
          <div className="row d-flex align-items-center mobile-reverse">
            <div className="col-md-12 col-sm-12">
              <img src={ImgGiving} alt="giving-thanks" className="img-gt mb30"/>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export const givingThanksPageQuery = graphql`
  query givingThanksPage($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
      }
    }
  }
`;
